import React, { useEffect, useState } from 'react';
import {
    Badge,
    Input,
    Space,
    Switch,
    Table,
    TableColumnsType,
    Tooltip,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { useRecoilState } from 'recoil';
import Header from '../components/Header';
import {
    Automations,
    Client,
    ClientTeam,
    OwnerOrganization,
} from '../interfaces';
import SystemTag from '../components/SystemTag';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import {
    ClientColumn,
    ClientParameters,
    getAccountingSystems,
    getClients,
    updateClientAutomation,
} from '../api/clients';
import {
    FilterValue,
    SorterResult,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';
import ClientMassActionToolbar from '../components/ClientMassActionToolbar';
import { renderDate, renderDateTime } from '../util/datetime';
import { APIError, Collection, notifyError } from '../api';
import QueryError from '../components/QueryError';
import { serviceTemplates } from '../util/constants';
import { clientParameters } from '../atoms';
import { sortOrder } from '../util/table';
import ModelStatusBadge from '../components/ModelStatusBadge';

const Clients = () => {
    const [params, setParams] = useRecoilState(clientParameters);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const clientsQuery: UseQueryResult<Collection<Client>, APIError> = useQuery(
        ['clients', params],
        () => getClients(Object.assign({}, params)),
        { keepPreviousData: true, retry: 1 }
    );

    const systemsQuery = useQuery(['systems'], () => getAccountingSystems(), {
        retry: 1,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const queryClient = useQueryClient();

    const onTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Client>
    ) => {
        const newParams = [
            'status',
            'accountingSystem',
            'trainingStatus',
        ].reduce<ClientParameters>(
            (acc, cur) => {
                const filter = filters[cur];
                acc[cur] = filter ? filter.join(',') : undefined;
                return acc;
            },
            {
                page: pagination.current,
                limit: pagination.pageSize,
                orderBy: sorter.order
                    ? (sorter.field as ClientColumn)
                    : 'createdAt',
                order: sorter.order
                    ? sorter.order === 'ascend'
                        ? 'asc'
                        : 'desc'
                    : 'desc',
            }
        );

        // Reset page when other options are changed
        if (newParams.page === params.page) newParams.page = 1;
        console.log('onTableChange', params, newParams);

        setParams({ ...params, ...newParams });
    };

    const toggleGrowlitheStatusMutation = useMutation(
        ({
            clientId,
            automationId,
            active,
        }: {
            clientId: number;
            automationId: number;
            active: boolean;
        }) => {
            const status = active ? 'ACTIVE' : 'SANDBOX';
            return updateClientAutomation(clientId, automationId, { status });
        },
        {
            onError: notifyError,
            onSuccess: (_, { clientId, automationId, active }) => {
                queryClient.setQueryData(
                    ['clients', params],
                    (oldData?: Collection<Client>) => {
                        if (!oldData || !oldData.items) return;
                        oldData.items = oldData.items.map((client) => {
                            if (client.id === clientId) {
                                const growlithe =
                                    client.automations.approval?.find(
                                        (a) =>
                                            a.id === automationId &&
                                            a.type === 'GROWLITHE'
                                    );
                                if (growlithe)
                                    growlithe.status = active
                                        ? 'ACTIVE'
                                        : 'SANDBOX';
                            }
                            return client;
                        });
                        return oldData;
                    }
                );
            },
        }
    );

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [params]);

    const handleSearch = (value: string) => {
        switch (true) {
            case /^-\S{16,24}$/.test(value):
                setParams({
                    ...params,
                    key: value,
                    page: 1,
                    searchQuery: undefined,
                    businessId: undefined,
                });
                break;
            case /^FI\d{8}$/.test(value):
                setParams({
                    ...params,
                    businessId: value,
                    page: 1,
                    searchQuery: undefined,
                    key: undefined,
                });
                break;
            default:
                setParams({
                    ...params,
                    searchQuery: value,
                    page: 1,
                    businessId: undefined,
                    key: undefined,
                });
                break;
        }
    };

    const clientColumns: TableColumnsType<Client> = [
        {
            title: () => (
                <Space
                    direction="vertical"
                    style={{ marginRight: 4 }}
                    onClick={(e) => e.stopPropagation()}
                    size="small"
                >
                    Nimi
                    <Input.Search
                        defaultValue={params.searchQuery}
                        onSearch={(value, e) => {
                            e?.stopPropagation();
                            handleSearch(value);
                        }}
                        allowClear
                        placeholder="Nimi / y-tunnus (FI...)"
                    />
                </Space>
            ),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            sortOrder: sortOrder(params, 'name'),
            showSorterTooltip: false,
            render: (name: string, client: Client) => (
                <Link to={`/clients/${client.id}`}>
                    <Text>{name}</Text>
                    <WarningBadge client={client} />
                    {client.displayName !== client.name && (
                        <>
                            <br />
                            <Text type="secondary">{client.displayName}</Text>
                        </>
                    )}
                </Link>
            ),
        },
        {
            title: 'Järjestelmä',
            dataIndex: 'accountingSystem',
            key: 'accountingSystem',
            render: (system: string) => (
                <SystemTag size="small" system={system} />
            ),
            filters:
                systemsQuery.data?.map((system) => ({
                    text: system.label,
                    value: system.name,
                })) ?? [],
            filtered: !!params.accountingSystem,
            filteredValue: params.accountingSystem
                ? params.accountingSystem.split(',')
                : undefined,
            width: 120,
        },
        {
            title: 'Tila',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) =>
                status === 'ACTIVE' ? (
                    <Badge status="success" text="Aktiivinen" />
                ) : (
                    <Badge
                        status="default"
                        text={<Text type="secondary">Ei aktiivinen</Text>}
                    />
                ),
            filters: [
                { text: 'Aktiivinen', value: 'ACTIVE' },
                { text: 'Ei aktiivinen', value: 'INACTIVE' },
            ],
            filterMultiple: false,
            filteredValue: params.status ? [params.status] : undefined,
            width: 110,
        },
        {
            title: 'Mallit',
            dataIndex: 'trainingStatus',
            key: 'trainingStatus',
            render: (_, client: Client) => (
                <div style={{ fontSize: 20, marginTop: -8, marginBottom: -8 }}>
                    <ModelStatusBadge models={client.mlModels ?? []} />
                </div>
            ),
            filters: [
                { text: 'Koulutuksessa', value: 'TRAINING' },
                { text: 'Tuotannossa', value: 'DEPLOYED' },
                { text: 'Epäonnistunut', value: 'FAILED' },
                { text: 'Ei malleja', value: 'UNDEFINED' },
            ],
            filterMultiple: false,
            filteredValue: params.trainingStatus
                ? [params.trainingStatus]
                : undefined,
            width: 90,
            align: 'center',
        },
        {
            title: 'Palvelumalli',
            dataIndex: 'serviceTemplate',
            key: 'serviceTemplate',
            width: 120,
            render: (serviceTemplate: string) => (
                <Tooltip style={{ fontSize: 32 }} title={serviceTemplate}>
                    {serviceTemplates[serviceTemplate]?.name || (
                        <Text type="danger">Ei asetettu</Text>
                    )}
                </Tooltip>
            ),
        },
        {
            title: 'IA',
            dataIndex: 'automations',
            key: 'automations',
            width: 60,
            render: (automations: Automations, client: Client) => {
                const growlithe = automations.approval?.find(
                    (a) => a.type === 'GROWLITHE'
                );
                const active = growlithe?.status === 'ACTIVE';
                const inactive = growlithe?.status === 'INACTIVE';
                return growlithe ? (
                    <Switch
                        size="small"
                        checked={active}
                        disabled={inactive}
                        onChange={(checked) =>
                            toggleGrowlitheStatusMutation.mutate({
                                clientId: client.id,
                                automationId: growlithe.id,
                                active: checked,
                            })
                        }
                    />
                ) : null;
            },
            align: 'center',
        },
        {
            title: () => (
                <Space
                    direction="vertical"
                    style={{ marginRight: 4 }}
                    onClick={(e) => e.stopPropagation()}
                    size="small"
                >
                    Organisaatio
                    <Input.Search
                        defaultValue={params.organization}
                        onSearch={(value, e) => {
                            e?.stopPropagation();
                            setParams({
                                ...params,
                                organization: value,
                                page: 1,
                            });
                        }}
                        allowClear
                        placeholder="Hae..."
                    />
                </Space>
            ),
            dataIndex: 'organization',
            key: 'organization',
            sorter: true,
            showSorterTooltip: false,
            sortOrder: sortOrder(params, 'organization'),
            render: (org?: OwnerOrganization) => (org ? org.name : '-'),
        },
        {
            title: () => (
                <Space
                    direction="vertical"
                    style={{ marginRight: 4 }}
                    onClick={(e) => e.stopPropagation()}
                    size="small"
                >
                    Tiimi
                    <Input.Search
                        defaultValue={params.team}
                        onSearch={(value, e) => {
                            e?.stopPropagation();
                            setParams({ ...params, team: value, page: 1 });
                        }}
                        allowClear
                        placeholder="Hae..."
                    />
                </Space>
            ),
            dataIndex: 'teams',
            key: 'teams',
            ellipsis: {
                showTitle: false,
            },
            render: (teams: ClientTeam[] = []) => (
                <Tooltip title={teams.map((t) => t.name).join(', ')}>
                    {teams.map((t) => t.name).join(', ') || '-'}
                </Tooltip>
            ),
        },
        {
            title: 'Aloituspvm',
            dataIndex: 'startDateForFetchingInvoices',
            key: 'startDateForFetchingInvoices',
            sorter: true,
            sortOrder: sortOrder(params, 'startDateForFetchingInvoices'),
            render: (startDateForFetchingInvoices: string) => (
                <Text type="secondary">
                    {renderDate(startDateForFetchingInvoices)}
                </Text>
            ),
            width: 110,
        },
    ];

    return (
        <Content>
            <Header />
            <Space
                align="start"
                size="large"
                style={{
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: selectedRowKeys.length ? 'column' : 'row',
                    transition: 'all 0.3s ease-in-out',
                }}
            >
                <Title
                    level={2}
                    style={{
                        margin: '16px 16px 16px 0',
                    }}
                >
                    Asiakkaat
                </Title>
                <ClientMassActionToolbar
                    clients={
                        selectedRowKeys
                            .map((id) =>
                                clientsQuery.data?.items.find(
                                    (c) => c.id === id
                                )
                            )
                            .filter(
                                (client) => client !== undefined
                            ) as Client[]
                    }
                />
            </Space>
            <QueryError query={clientsQuery} />
            <Table
                style={{ minWidth: '100%' }}
                bordered
                size="middle"
                pagination={{
                    total: clientsQuery.data?.totalCount,
                    pageSize: params.limit,
                    current: params.page,
                    showSizeChanger: true,
                }}
                rowKey="id"
                loading={clientsQuery.isLoading || clientsQuery.isPreviousData}
                dataSource={clientsQuery.data?.items.slice(0, params.limit)}
                columns={clientColumns}
                onChange={onTableChange}
                footer={() => `${clientsQuery.data?.totalCount || 0} tulosta`}
                rowSelection={{
                    onChange: (selectedRowKeys: number[]) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                    selectedRowKeys,
                }}
            />
        </Content>
    );
};

export default Clients;

const WarningBadge = (props: { client: Client }) => {
    const { client } = props;
    const isActive = client.status === 'ACTIVE';
    const dateLastFetched = new Date(client.invoicesLastFetched ?? 0);
    const startDate = new Date(client.startDateForFetchingInvoices);
    const hasStarted = startDate <= new Date();

    let message: string | undefined = '';

    // Check if invoices have never been fetched and start date is in the past and client is active
    if (dateLastFetched.getTime() === 0 && hasStarted && isActive) {
        message = `Asiakkaan laskuja ei ole haettu koskaan`;
    }

    // Check if invoices were last fetched more than an hour ago and start date is in the past
    if (
        new Date().getTime() - dateLastFetched.getTime() > 3600 * 1000 &&
        hasStarted &&
        isActive
    ) {
        message = `Asiakkaan laskut haettu viimeksi ${renderDateTime(
            client.invoicesLastFetched
        )}`;
    }

    // Check if the client does not have coa and is active
    if (!client.hasCoa && isActive) {
        message = `Asiakkaalla ei ole tilikarttaa`;
    }

    if (!message) return null;

    return (
        <Tooltip title={message}>
            <Badge
                style={{ marginLeft: 8 }}
                count={<ExclamationCircleTwoTone twoToneColor="red" />}
            ></Badge>
        </Tooltip>
    );
};
